import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    email: '',
    phone: '',
    message: '',
    consent: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.consent) {
      alert('Bitte erteilen Sie die Zustimmung, bevor Sie fortfahren.');
      return;
    }

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((result) => {
      console.log(result.text);
      alert('Nachricht erfolgreich gesendet!');
    }, (error) => {
      console.log(error.text);
      alert('Fehler beim Senden der Nachricht, bitte versuchen Sie es später noch einmal.');
    });

    // Formular nach dem Absenden zurücksetzen
    setFormData({
      from_name: '',
      email: '',
      phone: '',
      message: '',
      consent: false
    });
  };

  return (
    <Container className="contact-section">
      <Row>
        <Col md={6}>
          <h2>Kontaktieren Sie uns noch heute</h2>
          <p>
            Wenden Sie sich an die Trans Rapido GmbH für alle Anfragen, Angebote oder Kooperationen.
            Wir sind hier, um Ihnen bei all Ihren Reinigungs-, Transport-, Umzugs- und Bauanforderungen zu helfen.
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name *</Form.Label>
              <Form.Control
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Vorname und Nachname"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email Adresse *</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Adresse"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Ihre Telefonnummer *</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Ihre Telefonnummer"
                required
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Nachricht</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Nachricht"
              />
            </Form.Group>

            <Form.Group controlId="formConsent">
              <Form.Check
                type="checkbox"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                label="Ich erlaube dieser Website, meine Angaben zu speichern, damit sie auf meine Anfrage antworten kann."
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              ABSENDEN
            </Button>
          </Form>
        </Col>
        <Col md={6}>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2375.186900012699!2d9.963878815770874!3d53.55323148002508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18f0753c52e8f%3A0x421858e8fcbff1e7!2sBeim%20Gr%C3%BCnen%20J%C3%A4ger%2010%2C%2020359%20Hamburg%2C%20Germany!5e0!3m2!1sen!2sus!4v1630508712980!5m2!1sen!2sus"
              width="100%"
              height="400"
              allowFullScreen=""
              loading="lazy"
              title="Google Maps"
            ></iframe>
          </div>
          <div className="contact-info">
            <h3>Kontaktieren Sie uns</h3>
            <p><strong>Telefon:</strong> 0152/589 053 74</p>
            <p><strong>Email:</strong> info@rapido-facility.de</p>
            <a><strong>Standort:</strong> Beim Grünen Jäger 10, Hamburg, HH 20359 DE</a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactSection;
