import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ServiceCard from '../../service/CardService';
import data from "../../Data/home-Card-data.json" ; 
// Stelle sicher, dass der Pfad korrekt ist


function SectionCards() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Normalerweise würde man hier einen API-Aufruf machen. Für diese Demonstration laden wir die Daten aus der JSON-Datei.
    setServices(data);
  }, []);

  return (
    <Container className="services-section">
      <h2 style={{fontSize:"25px"}}>UNSERE DIENSTLEISTUNGEN</h2>
      <h3>Ihre Komplettlösung aus einer Hand</h3>
      <p>
        Wählen Sie Rapido Facility für all Ihre Reinigungs-, Transport-, Umzugs- und Bauvorhaben.
        Wir bieten umfassende Lösungen mit persönlichem Touch, um Ihre Erwartungen zu übertreffen.
      </p>
      <Row>
        {services.map((service, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <ServiceCard 
              title={service.title} 
              description={service.description} 
              image={service.image} 
              detailPage={service.detailPage}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}


export default SectionCards;
