import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from "react-router-dom";

function CookieBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Ich stimme zu"
      declineButtonText="Ich lehne ab"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#062767" }}
      buttonStyle={{ color: "black", fontSize: "13px" , backgroundColor:"#ffff" }}
      declineButtonStyle={{ color: "#fff", background: "#c82333", fontSize: "13px" }}
      expires={150}
      onAccept={() => {
        console.log("Cookies accepted");
      }}
      onDecline={() => {
        console.log("Cookies declined");
      }}
    >
      Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern. Durch die Nutzung unserer Website stimmen Sie allen Cookies gemäß unserer Cookie-Richtlinie zu. 
      <Link to ="/cookies" style={{ color: "gold" }}>Mehr erfahren</Link>
    </CookieConsent>
  );
}

export default CookieBanner;
