import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import image1 from '../../images/bewerbung.jpg';
import { Link } from 'react-scroll';
import { Link as RouterLink } from "react-router-dom";


const Karriere = () => {
  return (

    <div>

  
    <div className="custom-section-yeaser">
      <Container>
        <Row className="align-items-center">
          <Col md={8}>
            <h1 style={{marginBottom:"25px"}}>WIR SIND STOLZ AUF UNSERE ARBEIT</h1>
            <h1 style={{marginBottom:"40px"}}>Werden Sie ein wertvolles und geschätztes Mitglied unseres familiengeführten Unternehmens.</h1>
          </Col>
          <Col md={4}>
            <img src={image1} alt="Image" className="img-fluid-yeaser"  style={{marginBottom:"25px"}}/>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <h2>Wir freuen uns auf Ihre Bewerbung!</h2>
        </Row>
        
      </Container>

      
    </div>
    <div className="custom-section-yeaser2">
    <Container>
      <Row className="justify-content-center text-center">
        <Col md={6}>
          <h2>Wir freuen uns auf Ihre Bewerbung!</h2>
        </Col>
      </Row>
      <Row className="justify-content-center text-center mt-3">
        <Col md={3}>
        <RouterLink to="/kontakt">
          <Button variant="outline-dark" size="lg">
         
          
         
          BEWERBEN
          </Button>
          </RouterLink>
        </Col>
      </Row>
    </Container>
  </div>

  </div>
  );
};

export default Karriere;
