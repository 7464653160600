import React from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
// Import the CSS file

function ServiceCard({ title, description, image, detailPage }) {
    return (
      <Link to={detailPage} className="card-link">
        <Card className="service-card">
          <Card.Img variant="top" src={image} className="card-img-top" />
          <Card.Body>
            <Card.Title className='text-start' style={{color:"#141C26" , marginTop:"10px", marginBottom:"10px"}}>{title}</Card.Title>
            <Card.Text className='text-start'>{description}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    );
}

export default ServiceCard;
