import React,{useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import image1 from "../../images/Gebeaudereinigung-services.jpg"
import { Link } from'react-router-dom';


const ReinigungPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Container>
   
    <div className="custom-section-reinigung" >
      <div>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={image1} alt="Gebäudereinigung" className="img-fluid" />
          </Col>
          <Col md={6} className="text-content">
            <h5>Reinigungsdienstleistungen</h5>
            <p>
            Unser erfahrenes Team bietet professionelle Reinigungsdienstleistungen für Gebäude und Büros, um eine saubere und einladende Umgebung für Ihr Unternehmen sicherzustellen. Wir verstehen, dass ein gepflegtes Arbeitsumfeld entscheidend für Produktivität und Wohlbefinden ist. Deshalb legen wir großen Wert auf Qualität und Zuverlässigkeit.
            Unsere Dienstleistungen umfassen:
            </p>
            <ul>
              <li> <div style={{fontWeight:"bolder"}}>Umfassende Reinigung:</div> Gründliche Reinigung aller Bereiche Ihres Gebäudes, einschließlich Büros, Empfangsbereiche, Konferenzräume und Gemeinschaftsflächen.</li>
              <li> <div style={{fontWeight:"bolder"}}>Moderne Ausrüstung:</div> Wir nutzen hochmoderne Reinigungsgeräte, die für maximale Effizienz und Sauberkeit sorgen.</li>
              <li> <div style={{fontWeight:"bolder"}}>Umweltfreundliche Produkte:</div> Unsere Reinigungsmittel sind umweltfreundlich und nachhaltig, um die Gesundheit Ihrer Mitarbeiter zu schützen und die Umwelt zu schonen.</li>
              <li> <div style={{fontWeight:"bolder"}}>Individuelle Reinigungspläne:</div> Wir erstellen maßgeschneiderte Reinigungspläne, die auf die spezifischen Bedürfnisse und Anforderungen Ihres Unternehmens abgestimmt sind.</li>
              <li> <div style={{fontWeight:"bolder"}}>Bauendreinigung:</div> Gründliche Reinigung nach Bau- oder Renovierungsarbeiten, um Ihr Gebäude bezugsfertig zu machen.</li>
            </ul>
            <p>
              Überlassen Sie uns die Sauberkeit, damit Sie sich ohne Ablenkungen auf Ihre Kernaktivitäten konzentrieren können. Vertrauen Sie auf unsere Expertise für hervorragende Ergebnisse und eine einladende Arbeitsumgebung.
            </p>
            <Link to = "/kontakt">
           
            <Button variant="primary"> TERMIN VEREINBAREN </Button>
            </Link>
          </Col>
        </Row>
      </div>
      
    
    </div>
    <div className='text-center' style={{backgroundColor:"#002244" , color:"white" , padding:"40px"}}>
     
    <h1 style={{marginBottom:"40px" }} >Lassen Sie uns sprechen</h1>
       
      
    <Link to="/kontakt">
      <Button variant="outline-light" size="lg">KONTAKTIEREN SIE UNS</Button>
    </Link>
    </div>
    </Container>
  );
};

export default ReinigungPage;
