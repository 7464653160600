import React,{useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import image1 from "../../images/büro-services.jpg"
import { Link } from'react-router-dom';


const BuroCard = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Container style={{minHeight:"1000px"}}>

  
<div className="custom-section-reinigung">
      <div>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={image1} alt="Büroumzüge"  />
          </Col>
          <Col md={6} className="text-content">
            <h5>Büroumzüge</h5>
            <p>
            Mit unseren professionellen Umzugs- und Transportservices verlegen Sie Ihr Büro reibungslos und effizient. Unser Leistungsspektrum umfasst:
            </p>
            <ul>
            <li> <div style={{fontWeight:"bolder"}}>Sorgfältige Verpackung:</div> Sicheres und systematisches Verpacken aller Büromaterialien und -ausstattungen.</li>
              <li> <div style={{fontWeight:"bolder"}}>Sicherer Transport:</div> Zuverlässiger und sicherer Transport Ihrer Bürogegenstände zum neuen Standort.</li>
              <li> <div style={{fontWeight:"bolder"}}>Aufbau und Installation:</div>Fachgerechter Aufbau und Installation Ihrer Büroeinrichtung am neuen Standort.</li>
             
            </ul>
            <p>
            Unser erfahrenes Team gewährleistet einen stressfreien Umzug, indem es jeden Gegenstand mit größter Sorgfalt und Präzision behandelt. Vertrauen Sie auf unsere Expertise für einen reibungslosen und termingerechten Übergang.
            Kontaktieren Sie uns für weitere Informationen und ein maßgeschneidertes Angebot für Ihren Büroumzug.
            </p>
            <Link to="/kontakt">
              <Button variant="primary">KONTAKTIEREN SIE UNS</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
    
    <div className='text-center' style={{backgroundColor:"#002244" , color:"white" , padding:"40px"}}>
     
    <h1 style={{marginBottom:"40px" }} >Lassen Sie uns sprechen</h1>
       
      
    <Link to="/kontakt">
      <Button variant="outline-light" size="lg">KONTAKTIEREN SIE UNS</Button>
    </Link>
    </div>

    </Container>
  );
};

export default BuroCard;
