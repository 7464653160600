import React,{useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import image1 from "../../images/trnsport-services.jpg"
import { Link } from'react-router-dom';


const TransProt = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Container style={{minHeight:"1000px"}}>

  
<div className="custom-section-reinigung">
      <div>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={image1} alt="Transport" className="img-fluid" />
          </Col>
          <Col md={6} className="text-content">
            <h5>TRANSPORT</h5>
            <p>
            Müssen Sie Ihre Waren innerhalb Deutschlands transportieren? Unsere zuverlässigen Transportdienste sorgen für eine sichere und pünktliche Lieferung an Ihr gewünschtes Ziel. Wir bieten:
            </p>
            <ul>
               <li> <div style={{fontWeight:"bolder"}}>Transport von Einzelstücken</div> </li>
              <li> <div style={{fontWeight:"bolder"}}>Transport größerer Mengen</div> </li>
             
            </ul>
            <p>
            Jeden Transport behandeln wir mit größter Sorgfalt und Professionalität. Vertrauen Sie darauf, dass wir Ihre Güter sicher und effizient ans Ziel bringen.
Kontaktieren Sie uns, um mehr über unsere Transportdienste zu erfahren und ein maßgeschneidertes Angebot zu erhalten.
            </p>
                
             
      
            <Link to="/kontakt">
              <Button variant="primary">KONTAKTIEREN SIE UNS</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
    
    <div className='text-center' style={{backgroundColor:"#002244" , color:"white" , padding:"40px"}}>
     
    <h1 style={{marginBottom:"40px" }} >Lassen Sie uns sprechen</h1>
       
      
    <Link to="/kontakt">
      <Button variant="outline-light" size="lg">KONTAKTIEREN SIE UNS</Button>
    </Link>
    </div>

    </Container>
  );
};

export default TransProt;




