// src/components/BauDiensLeistung.js
import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image1 from "../../images/bauarbeiter-services.jpg"; // Ensure the path is correct

const BauDiensLeistung = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container style={{ minHeight: "1000px" }}>
            <div className="custom-section-reinigung">
                <div>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <img src={image1} alt="Baudienstleistungen"  />
                        </Col>
                        <Col md={6} className="text-content">
                            <h5 >Baudienstleistungen</h5>
                            <p>
                            Wir bieten ein umfassendes Spektrum an Baudienstleistungen für Wohn- und Gewerbeprojekte. Unsere Dienstleistungen umfassen:
                            </p>
                            <ul>
                            <li> <div style={{fontWeight:"bolder"}}>Elektroarbeiten:</div> Installation und Wartung elektrischer Systeme.</li>
              <li> <div style={{fontWeight:"bolder"}}>Sanitärarbeiten:</div> Planung, Installation und Instandhaltung von Sanitäranlagen.</li>
              <li> <div style={{fontWeight:"bolder"}}>Malerarbeiten:</div>Professionelle Innenanstriche.</li>
              <li> <div style={{fontWeight:"bolder"}}>Bodenlegearbeiten:</div> Verlegung von Parkett, Laminat und Teppich.</li>
              <li> <div style={{fontWeight:"bolder"}}>Fliesenlegearbeiten:</div> Verlegung von Wand- und Bodenfliesen.</li>
              <li> <div style={{fontWeight:"bolder"}}>Trockenbau:</div> Erstellung von Wänden, Decken und Böden.</li>
                            </ul>
                            <p>
                            Unsere erfahrenen Fachkräfte garantieren erstklassige Handwerkskunst und termingerechte Fertigstellung. Kontaktieren Sie uns, um mehr zu erfahren und ein persönliches Beratungsgespräch zu vereinbaren.
                            </p>
                            <Link to="/kontakt">
                                <Button variant="primary">TERMIN VEREINBAREN</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='text-center' style={{ backgroundColor: "#002244", color: "white", padding: "40px" , marginBotton:"30px"}}>
                <h1 style={{ marginBottom: "40px" }}>Lassen Sie uns sprechen</h1>
                <Link to="/kontakt">
                    <Button variant="outline-light" size="lg">KONTAKTIEREN SIE UNS</Button>
                </Link>
            </div>
        </Container>
    );
};

export default BauDiensLeistung;
