import React from "react";
import {Navbar, Container, Nav, ProgressBar, Row,Col, Button, Image, Card, Carousel } from "react-bootstrap"
import Homepage from "./compoment/Home/hompage";



import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ImpressumSection from "./compoment/ulitiy/impressum";
import DatenSchutzSection from "./compoment/ulitiy/dataschutz";

import NavigationBar from "./compoment/Navbar/navbar";
import Footer from "./compoment/Navbar/Footer";
import HomepageInfo from "./compoment/Info/hauptPage";
import Karriere from "./compoment/Home/karrierPage";
import ContactSection from "./compoment/Home/contactSection";
import ReinigungPage from "./compoment/CardPage/reinigungPage";
import BauDiensLeistung from "./compoment/CardPage/BauDiensLeistungPage";
import BuroCard from "./compoment/CardPage/BuroCard";
import ManagementCard from "./compoment/CardPage/managementCard";
import TransProt from "./compoment/CardPage/Transport";
import ReiningungZ from "./compoment/CardPage/reinigung_Page";
import CookiesBanner from "./compoment/ulitiy/cookis";
import Cookis from "./compoment/ulitiy/CookiesInfo";


function App() {

  return (
    <div>
     

    
<BrowserRouter>
<NavigationBar/>

<Routes>


<Route index element={<Homepage />} />


          <Route path="/career" element={<Karriere />} />
          <Route path="/reinigung" element={<ReinigungPage/>} />
          <Route path="/kontakt" element={<ContactSection />} />
          <Route path="/buro" element={<BuroCard />} />
          <Route path="/management" element={<ManagementCard />} />
          <Route path="/transprt" element={<TransProt />} />
          <Route path="/reinigungz" element={<ReiningungZ />} />

          <Route path="/baudiensleistung" element={<BauDiensLeistung />} />
          <Route path="/impressum" element={<ImpressumSection/>} />
          <Route path="/datenschutz" element={<DatenSchutzSection/>} />
          <Route path="/cookies" element={<Cookis/>} />
       
          
</Routes>
<Footer/>
<CookiesBanner/>

</BrowserRouter>


          
         




      
    </div>
  
  );
}

export default App;