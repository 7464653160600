import React,{useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import image1 from "../../images/management-services.jpg"
import { Link } from'react-router-dom';


const ManagementCard = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Container style={{minHeight:"1000px"}}>

  
<div className="custom-section-reinigung">
      <div>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={image1} alt="Facility Management" className="img-fluid" />
          </Col>
          <Col md={6} className="text-content">
            <h5>Facility Management</h5>
            <p>
            Unsere Facility Management-Dienstleistungen decken alle Aspekte der Instandhaltung und Optimierung Ihrer Einrichtungen ab. Wir bieten:
            </p>
            <ul>
            <li> <div style={{fontWeight:"bolder"}}>Wartungsplanung:</div>Proaktive Planung und Durchführung regelmäßiger Wartungsarbeiten zur Vermeidung von Ausfällen und zur Sicherstellung eines reibungslosen Betriebs.</li>
              <li> <div style={{fontWeight:"bolder"}}>Verbesserung der Energieeffizienz:</div> Zuverlässiger und sicherer Transport Ihrer Bürogegenstände zum neuen Standort.</li>
              <li> <div style={{fontWeight:"bolder"}}>Maßgeschneiderte Lösungen:</div>Anpassung unserer Dienstleistungen an die spezifischen Bedürfnisse und Anforderungen Ihres Unternehmens.</li>
            </ul>
            <p>
            Mit unserer Expertise stellen wir sicher, dass Ihre Einrichtungen effizient betrieben werden, was zu einer höheren Produktivität und Kosteneffizienz führt. Vertrauen Sie auf unser professionelles Facility Management, um den optimalen Betrieb Ihrer Einrichtungen zu gewährleisten.
            Kontaktieren Sie uns, um mehr über unsere Facility Management-Dienstleistungen zu erfahren und ein individuelles Angebot zu erhalten.
            </p>
            <Link to="/kontakt">
              <Button variant="primary">TERMIN VEREINBAREN</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
    
    <div className='text-center' style={{backgroundColor:"#002244" , color:"white" , padding:"40px"}}>
     
    <h1 style={{marginBottom:"40px" }} >Lassen Sie uns sprechen</h1>
       
      
    <Link to="/kontakt">
      <Button variant="outline-light" size="lg">KONTAKTIEREN SIE UNS</Button>
    </Link>
    </div>

    </Container>
  );
};

export default ManagementCard;
