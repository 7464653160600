import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button, Navbar, Nav } from 'react-bootstrap';
import HeroSection from './heroSectioon';
import InfoSection from './InfoSection';
import SectionCards from './CardSection';
import ContactSection from './contactSection';
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink, Element } from 'react-scroll';

const Homepage = () => {
  const [showCards, setShowCards] = useState(false);
  const [showHero, setShowHero] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const sectionCardsRef = useRef(null);
  const sectionHeroRef = useRef(null);
  const sectionInfoRef = useRef(null);
  const sectionContactRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCards(true);
      if (sectionCardsRef.current) {
        window.scrollTo({
          top: sectionCardsRef.current.offsetTop + 50, 
          behavior: 'smooth'
        });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInfo(true);
      if (sectionInfoRef.current) {
        window.scrollTo({
          top: sectionInfoRef.current.offsetTop + 50, 
          behavior: 'smooth'
        });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHero(true);
      if (sectionHeroRef.current) {
        window.scrollTo({
          top: sectionHeroRef.current.offsetTop + 50, 
          behavior: 'smooth'
        });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Element name="hero-section" id="hero-section" ref={sectionHeroRef}>
        {showHero && <HeroSection />}
      </Element>

      <Element name="info-section" id="info-section" ref={sectionInfoRef}>
        {showInfo && <InfoSection />}
      </Element>

      <Container>
        <Element name="cards-section" id="cards-section" ref={sectionCardsRef}>
          {showCards && <SectionCards />}
        </Element>
        <Element name="contact-section" id="contact-section" ref={sectionContactRef}>
          <ContactSection />
        </Element>
      </Container>
    </div>
  );
};

export default Homepage;
