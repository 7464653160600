import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// Für benutzerdefinierte Stile

const Footer = () => {
  return (
    <div style={{backgroundColor:"black" ,marginTop:"30px"}}>
   
   
    <footer className="footer">
     
        <Row>
          <Col md={6} className="footer-left">
            <h3>TRANS RAPIDO GMBH</h3>
          </Col>
          <Col md={6} className="footer-right">
            <ul className="footer-nav">
            
            <Link to ="/datenschutz"> Datenschutz </Link>
            <Link to ="/impressum">  <li>Impressum</li> </Link>
            </ul>
          </Col>
        </Row>
        <Row>
          
        </Row>
     
    </footer>
   
    </div>
  );
};

export default Footer;
