import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';


function HeroSection() {
    return (
      <div className="hero-section">
        <div className="hero-text">
          <h3>Rapido Facility</h3>
          <p>Umfassende Reinigungs-, Transport- und Baudienstleistungen aus einer Hand</p>
        </div>
      </div>
    );
  }
  
  export default HeroSection;