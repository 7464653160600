import React, { useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

import { Link as ScrollLink } from 'react-scroll';
import logo from '../../images/logo1.png'; // Stellen Sie sicher, dass der Pfad korrekt ist

function NavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);

  const handleToggleClick = () => {
    setExpanded(expanded ? false : "expanded");
  };

  const handleNavClick = () => {
    setExpanded(false);
  };

  const handleNavigation = (sectionId) => {
   
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
      }, 100); // Kurze Verzögerung, um sicherzustellen, dass die Startseite geladen ist
    } else {
      document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    }
    setExpanded(false); // Menü nach der Navigation schließen
  };

  return (
    <Navbar style={{ backgroundColor: "#141C26", padding:"15px"}} expanded={expanded} onToggle={handleToggleClick} expand="lg" className="custom-navbar sticky-top">
      <Container>
        <Navbar.Brand href="/" className="custom-brand">
          <img

            src={logo}
            alt="Logo"
            width="230px"
            height="50px"
            className="d-inline-block align-top"
            style={{ marginRight: '10px', backgroundColor: "white" }} // Abstand zwischen Logo und Text
          />
        
        </Navbar.Brand>
        <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{
          backgroundColor: "transparent", // Hintergrund bleibt wie die Navbar
          border: "none", // Entfernt den Standardrahmen
        }}
        onClick={handleToggleClick}
      >
        <span
          style={{
            display: "block",
            width: "22px",
            height: "2px",
            backgroundColor: "white", // Linien werden weiß
            margin: "4px 0",
          }}
        ></span>
        <span
          style={{
            display: "block",
            width: "22px",
            height: "2px",
            backgroundColor: "white", // Linien werden weiß
            margin: "4px 0",
          }}
        ></span>
        <span
          style={{
            display: "block",
            width: "22px",
            height: "2px",
            backgroundColor: "white", // Linien werden weiß
            margin: "4px 0",
          }}
        ></span>
      </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto custom-nav">
           

         
           
            <Nav.Link className="custom-nav-link" onClick={() => handleNavigation('hero-section')}>
              Home
            </Nav.Link>
            <Nav.Link className="custom-nav-link" onClick={() => handleNavigation('info-section')}>
              Über Uns
            </Nav.Link>
            <Nav.Link className="custom-nav-link" onClick={() => handleNavigation('cards-section')}>
              Service
            </Nav.Link>
          
          
             
              <Nav.Link as={RouterLink} to="/career" onClick={handleNavClick} className="custom-nav-link">Karriere</Nav.Link>
           
            <Button variant="outline-light" className="custom-buttonm" onClick={() => handleNavigation('contact-section')}>
              KONTAKT
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
