import React, { useEffect, useRef, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from'react-scroll';
function InfoSection() {

    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect();
              }
            });
          },
          { threshold: 0.1 }
        );
    
        if (sectionRef.current) {
          observer.observe(sectionRef.current);
        }
    
        return () => {
          if (sectionRef.current) {
            observer.unobserve(sectionRef.current);
          }
        };
      }, []);

    return (
        <div className="info-section">
          <Container>

         
        <div className="info-text" >
          <h2>Umfassende Dienstleistungen</h2>
          <h3>Ihr verlässlicher Partner für Service auf höchstem Niveau in Hamburg</h3>
          <p>
            Rapido Facility bietet ein umfassendes Dienstleistungsspektrum, das Gebäudereinigung, Büroreinigung,
            Baudienstleistungen, Facility Management, Umzüge mit Transport, Renovierungsarbeiten sowie
            Baudienstleistungen umfasst. Mit einem engagierten Team und 20 Jahren Berufserfahrung ist das
            Unternehmen in Hamburg tätig.
          </p>
          <Button variant="outline-light" href="#contact" className="custom-buttonm">
          <Link to="contact-section" smooth={true} duration={1000}>
          KONTAKTIEREN SIE UNS
                </Link></Button>
        </div>
        </Container>
        <div className="info-image">
          
        </div>
      </div>
    );
  }
  
  export default InfoSection;