import React,{useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import image1 from "../../images/reno.png"
import { Link } from'react-router-dom';


const ReiningungZ = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Container style={{minHeight:"1000px"}}>

  
<div className="custom-section-reinigung">
      <div>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={image1} alt="Gebäudesanierung" className="img-fluid" />
          </Col>
          <Col md={6} className="text-content">
              <h5>SANIERUNGSARBEITEN</h5>
              <p>
                Erwecken Sie Ihr Gebäude zu neuem Leben mit unseren umfassenden Sanierungsarbeiten. Wir bieten:
              </p>
              <ul style={{fontWeight:"bold"}}>
                <li>Elektrische Upgrades</li>
                <li>Sanitärmodernisierungen</li>
                <li>Malerarbeiten im Innenbereich</li>
                <li>Bodenrenovierungen</li>
              </ul>
              <p>
                Unser erfahrenes Team sorgt für präzise und hochwertige Arbeiten, die sowohl die Ästhetik als auch die Funktionalität Ihrer Räumlichkeiten verbessern.
                Kontaktieren Sie uns, um weitere Informationen über unsere Sanierungsarbeiten zu erhalten und ein maßgeschneidertes Angebot anzufordern.
              </p>
              <Link to="/kontakt">
                <Button variant="primary">KONTAKTIEREN SIE UNS</Button>
              </Link>
            </Col>
        </Row>
      </div>
    </div>
   
    <div className='text-center' style={{backgroundColor:"#002244" , color:"white" , padding:"40px"}}>
     
    <h1 style={{marginBottom:"40px" }} >Lassen Sie uns sprechen</h1>
       
      
    <Link to="/kontakt">
      <Button variant="outline-light" size="lg">KONTAKTIEREN SIE UNS</Button>
    </Link>
    </div>

    </Container>
  );
};

export default ReiningungZ;
